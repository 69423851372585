<template>
  <div class="container">
    <h1>Resultado</h1>

    <b-table :items="stats" :fields="fields">
      <template v-slot:cell(nickname)="data">
        <router-link :to="{ name: 'PlayerSingle', params: { id: data.item.steamid64 }}">
          {{ data.value }}
        </router-link>
      </template>

    </b-table>

  </div>
</template>

<script>
export default {
  name: "MatchCompare",
  computed: {
    isPremium: function() {
      return (
        this.currentUser &&
        this.currentUser.subscription_package &&
        this.currentUser.subscription_package.package != "free"
      );
    },
  },
  data() {
    return {
      stats: [],
      fields: [
        { key: 'nickname', label: 'Nick', sortable: true },
        { key: 'kills', label: 'K', sortable: true },
        { key: 'assists', label: 'A', sortable: true },
        { key: 'deaths', label: 'D', sortable: true },
        { key: 'headshot_percentage', label: 'HS%', sortable: true },
        { key: 'rounds_played', label: 'Rounds', sortable: true },
        { key: 'kills_4', label: '4K', sortable: true },
        { key: 'kills_5', label: 'Ace', sortable: true },
        { key: 'kdr', label: 'KDR', sortable: true },
        { key: 'adr', label: 'ADR', sortable: true },
      ]
    };
  },
  methods: {
    initialize() {
      var self = this;

      this.$http
        .post(`matches/compare.json`, { ids: window.location.search.replace('?matches=', '').split(',') })
        .then(response => {
          this.stats = response.data;
        });

    },



  },
  mounted() {
    this.initialize();
  },
  watch: {
    // "$route.params.id": function(id) {
    //   this.initialize();
    // },
    // "$route.params.round": function(round) {
    //   // this.loadRound(round);
    // }
  },
  components: {

  }
};
// Header
</script>

<style lang="sass" scoped>

</style>
